import Glide from '@glidejs/glide'
import  loadComponentArticle from './infiniteScrooll';
// import { InfiniteScroll } from 'tw-elements';

var glide = new Glide('.glide', {
    gap: 10,
    type: 'carousel',
    focusAt: 1,
    perView: 1.3,
    autoplay: 3500,
    animationDuration: 700,
})

glide.mount()

// Tambahkan event listener untuk complete.te.infiniteScroll
window.addEventListener('complete.te.infiniteScroll', () => {
    // Panggil loadComponentArticle saat complete.te.infiniteScroll terpicu
    loadComponentArticle(baseUrl + '/home/infinite');
});
